// COLORS

$bg-color: #F8F9FD;
$white: #FFFFFF;
$black: #2C2C2C;
$primary-color: #6879C4;
$primary-light-color: #E7EAF7;
$warn: #FA5C4F;
$success: #57bd80;
$grey: #BDBDBD;
$light-grey: #939393;

$text-color: #666666;
$text-light: #939393;

// METRICS

$xl-screen: 1200px;
$lg-screen: 1024px;
$md-screen: 900px;
$sm-screen: 700px;
$xs-screen: 576px;

$margin-xl : 50px;
$margin-lg : 30px;
$margin-md : 20px;
$margin-sm : 15px;
$margin-xs : 10px;
$radius: 5px;

// FONTS SIZE

$h1: 28px;
$medium: 14px; 
$regular: 13px;
$small: 11px; 
 