@import '../../styles/_variables.scss';

.tileGraph {
  display: flex;
  align-items: center;
  border: solid 1px $grey;
  border-radius: $radius;
  height: 100px;
  transition: all .3s ease-in-out;
  cursor: pointer;

  &.selected {
    background: $primary-color;
    p {
      color: $white;
    }

  }

  &.disabled {
    background: #F8F9FD;
    border: solid 1px $grey;
    pointer-events: none;
    .containerIcon {
      opacity: 0.2;
    }
    .content {
      opacity: 0.4;
    }
  }

  .containerIcon {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-left: $margin-xs;
    width: 45px;
    transition: all .3s ease-in-out;
  }
  .content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    flex: 1;
    padding: 0 $margin-sm 0 $margin-xs;
    transition: all .3s ease-in-out;
    p:first-child {
      margin-bottom: 3px;
    }
    p:last-child {
      font-size: $small;
      margin-bottom: 0;
    }
  }
}