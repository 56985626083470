@import "./_variables.scss";
@import "./_mixins.scss";
@import "./_fonts.scss";

html,
body,
#root {
  height: 100%;
  min-height: 100%;
}

body {
  margin: 0;
  font-family: "OpenSans", sans-serif;
  font-size: $regular;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  --bg-dropzone: transparent;
}

* {
  box-sizing: border-box;
}

.App {
  display: flex;
  flex-wrap: wrap;
  height: 100%;
  flex-direction: column;
  justify-content: flex-start;
  background-color: $bg-color;
  overflow-x: hidden;
}

p {
  margin: 0 0 $margin-xs 0;
}

h1,
h2,
h3,
h4 {
  margin-top: 0;
}

p,
a,
li,
input,
textarea {
  font-family: "OpenSans", sans-serif;
  color: $text-color;
  font-size: $regular;
  &.bold {
    font-family: "OpenSans-SemiBold", sans-serif;
  }
  &.small {
    font-size: $small;
  }
}

p.error {
  color: $warn;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  box-shadow: 0 0 0 30px transparent inset !important;
}

:focus-visible {
  outline: none;
}

button {
  align-items: center;
  justify-content: center;
  font-family: "OpenSans-SemiBold", sans-serif;
  font-size: $medium;
  background-color: $primary-color;
  padding: 8px 20px;
  color: white;
  border: solid 2px $primary-color;
  border-radius: $radius;
  text-align: center;
  white-space: nowrap;
  min-width: 165px;
  cursor: pointer;
  @include remove-tap-highlight();
  span {
    vertical-align: middle;
  }

  .icon {
    position: relative;
    top: -1px;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    height: 0px;
    &.loader > div {
      position: initial;
    }

    svg {
      position: relative;
      top: -50%;
      vertical-align: middle;
    }
  }

  .icon:not(.loader):first-child {
    margin-left: -5px;
  }

  span:first-child:not(.icon),
  .icon + span {
    position: relative;
    top: -1px;
  }

  span + .icon,
  .icon + span {
    margin-left: 10px;
  }

  &.secondary {
    background-color: $bg-color;
    border: solid 2px $primary-color;
    color: $primary-color;
  }

  &.disabled {
    opacity: 0.5;
    pointer-events: none;
    background-color: #c6c6c6;
    border: solid 2px #c6c6c6;
  }
}
