@import "../../../styles/variables.scss";
@import "../form-mixin.scss";

.container-input {
  position: relative;

  label {
    @include label;
  }

  &.inline {
    @include inline;
  }

  &:not(.inline) {
    label + .containerInput {
      margin-top: 10px;
    }
  }

  input {
    @include input();
    &:disabled {
      color: $light-grey;
    }
  }

  &.primary {
    @include primary;
  }

  .containerInput {
    position: relative;

    &.withIcon input {
      padding-right: 90px;
    }

    .clearable {
      top: 10px !important;
      padding-left: 9px !important;
      border-left: solid 1px $grey;
    }

    .clearable,
    .locker {
      position: absolute;
      top: 11px;
      right: 10px;
      font-size: 24px;
      color: $primary-color;
      padding-left: 10px;

      display: flex;
      align-items: center;



      svg {
        cursor: pointer;
      }

      p {
        position: relative;
        top: -2px;
        margin: 0 5px 0 0;
        font-family: 'OpenSans-SemiBold';
        color: $primary-color;
        cursor: pointer;
        &:hover {
          text-decoration: underline;
        }
      }
    }
  }
}
