@font-face {
	font-family: "OpenSans-Bold";
	src: url("../assets/fonts/OpenSans/OpenSans-Bold.ttf") format("truetype");
}

@font-face {
	font-family: "OpenSans-SemiBold";
	src: url("../assets/fonts/OpenSans/OpenSans-SemiBold.ttf") format("truetype");
}

@font-face {
	font-family: "OpenSans";
	src: url("../assets/fonts/OpenSans/OpenSans-Regular.ttf") format("truetype");
}

@font-face {
	font-family: "OpenSans-Italic";
	src: url("../assets/fonts/OpenSans/OpenSans-Italic.ttf") format("truetype");
}