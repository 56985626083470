@import "../../../styles/variables.scss";

.sortMobile {
  position: relative;
  display: flex;
  justify-content: flex-end;
  margin-bottom: $margin-xs;
  padding-top: 0px !important;
  >p{
    display: flex;
    align-items: center;
    justify-content: space-between;
    >span{
      font-family: 'OpenSans-SemiBold';
    }
    >svg{
      margin-left: $margin-xs;
    }
  }
  @media screen and (min-width: $sm-screen) {
    display: none;
  }
  ul {
    position: absolute;
    top: 100%;
    z-index: 1;
    margin: 0 0 0 0;
    padding: 0;
    background-color: $white;
    box-shadow: 0px 18px 32px rgba(208, 210, 218, 0.15);
    border-radius: 8px;
    li {
      list-style: none;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 5px;
      width: 100%;
      border-bottom: solid 1px $grey;
      >svg{
        margin-left: $margin-xs;
      }
    }
  }
}

.header {
  @media screen and (max-width: $sm-screen) {
    display: none;
  }
  display: flex;
  align-items: center;
  justify-content: space-between;
  .cell {
    display: flex;
    align-items: center;
    justify-content: flex-start;

    &.selected {
      .asc svg:first-child,
      .desc svg:last-child {
        color: $primary-color;
      }
    }

    p {
      font-family: 'OpenSans-SemiBold';
      color: $text-color;
      font-size: $regular;
    }
    .sort {
      position: relative;
      display: flex;
      max-height: 20px;
      width: 20px;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      font-size: 16px;
      > svg {
        position: absolute;
        color: $text-light;
      }
    }
  }
}
