.container-switch{
  label{
    display: block;
    font-size: 14px;
  }

  label+.switch{
    margin-top: 0px;
  }
}
