@import '../../styles/_mixins.scss';

.container {
  @include container();
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 100%;
  h2 {
    @include bold;
    font-size: 48px;
    color: $primary-color;
    margin-bottom: 10px;

  }
  h4 {
    font-family: "OpenSans-SemiBold";
    font-size: 18px;
  }
}