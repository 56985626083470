@import '../../styles/_mixins.scss';

.dashboard {
  background-color: $bg-color;
  .container {
    @include container;
    .backward {
      position: relative;
      top: -10px;
      left: 15px;
      @include backward();
      @media screen and (min-width: $md-screen) {
        position: absolute;
        left: 20px;
        top: 20px;
      }
    }
    .title {
      margin: 0px 10px;
      display: flex;
      align-items: center;
      .logo {
        height: 45px;
        img {
          height: 100%;
          border-radius: 3px;
          object-fit: contain;
        }
      }
      h2 {
        margin:  0px 10px;
        font-family: "OpenSans-SemiBold";
      }
    }
    .indicators {
      margin-top: 10px;
      display: flex;
      flex-direction: column;
      @media screen and (min-width: $md-screen) {
        flex-direction: row;
        flex-wrap: wrap;
      }
      .col {
        @include transition;
        width: 100%;
        @media screen and (min-width: $md-screen) {
          &-1 {
            width: 100%;
          }
          &-2 {
            width: calc(50%);
          }
          &-3 {
            width: calc(75%);
          }
          &-4 {
            width: calc(25%);
          }
        }

      }
      .block {
        height: 43vh;
        margin: 10px;
      }
  }
  }
}