@import '../../../../styles/_mixins.scss';

.container {
  .row {
    display: flex;
    justify-content: space-between;
    &:not(:first-child) {
      margin-top: 20px;
    }
    .col {
      width: calc(50% - 10px);
    }
  }
}