@import '../../../../styles/_mixins.scss';

.container {
  h2 {
    font-size: 13px;
    &:not(:first-child) {
      margin-top: 20px;
    }
  }
  .row {
    display: flex;
    justify-content: space-between;
    &:not(:first-child) {
      margin-top: 10px;
    }
    .col {
      width: calc(50% - 10px);
    }
  }
  .filters {
    margin-bottom: 15px;
    .row {
      display: flex;
      justify-content: space-between;
      width: 100%;
      &.filter {
        border: solid 1px $grey;
        border-radius: $radius;
        margin-bottom: 10px;
        .col {
          @include bold;
          color: rgba($black, 0.6);
          padding: 6px 10px;
          button {
            padding: 0px;
            min-width: 0px;
            background-color: transparent;
            border: none;
            img {
              margin-left: 5px;
            }
          }
        }
      }
      &.labels {
        margin-bottom: 10px;
        .col {
          padding: 0px 10px;
          color: rgba($black, 0.6);
        }
      }
      .col {
        width: calc(25%);
        display: flex;
        justify-content: flex-start;
        align-items: center;
        &:last-child {
          justify-content: flex-end;
        }
      }
    }
  }
  .graph {
    width: 100%;
    height: 400px;
    padding: 20px;
  }
}