@import "../../styles/mixins";

.overlay-modal{
  position: fixed;
  z-index: 999;
  top:0;
  height: 100%;
  width: 100%;
  background-color: rgba($black,0.2);
}

.modal{
  position: fixed;
  z-index: 1000;
  top:50%;
  left:50%;
  transform: translate(-50%,-50%);
  padding: 20px;
  border-radius: $radius;
  background-color: $white;
  .close {
    position: absolute;
    @include flex-center;
    background-color: transparent;
    border: none;
    font-size: 20px;
    color: $black;
    padding: 8px;
    min-width: 0px;
    top: 5px;
    right: 5px;
  }
}
