@import '../../../styles/_mixins.scss';

.informations {
  display: flex;
  .loader {
    width: 100%;
    min-height: 238px;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  >div {
    width: 50%;
    &:first-child {
      padding-right: $margin-sm;
    }
    &:last-child {
      padding-left: $margin-sm;
      .containerField {
        @include containerField();
      }
    }
  }
}