@import '../../../styles/_variables.scss';

.row{
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;

  // &.selected{
  //   background-color: $secondary-color;
  //   p {
  //     color: $white;
  //   }
  // }
}