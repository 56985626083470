@import '../../../styles/variables.scss';
@import '../form-mixin.scss';

.container-textarea{
  margin: 5px 0px 0px 0px;

  label{
    @include label;
  }

  &.inline{
    @include inline;
  }

  &:not(.inline){
    label+.container{
      margin-top: 10px;
    }
  }

  // &.disabled{
  //   pointer-events: none;
  //   opacity: .5;
  // }

  .input {
    @include input;

    padding-top: 16px;
    padding-bottom: 16px;
    resize: none;

    &:disabled {
      color: $light-grey;
    }

    &.disabled{
      pointer-events: none;
    }
  }

  &.primary {
    @include primary;
  }

  .container{
    width: 100%;
    min-width: 250px;

    .containerTextarea {
      position: relative;

      &.withLocker textarea {
        padding-right: 90px;
      }

      .locker {
        position: absolute;
        top: 11px;
        right: 10px;
        font-size: 24px;
        color: $primary-color;
        padding-left: 10px;
        cursor: pointer;

        display: flex;
        align-items: center;

        svg {
          cursor: pointer;
        }
  
        p {
          position: relative;
          top: -2px;
          margin: 0 5px 0 0;
          font-family: 'OpenSans-SemiBold';
          color: $primary-color;
          cursor: pointer;
          &:hover {
            text-decoration: underline;
          }
        }

      }

    }

    .count-characters{
      text-align: right;
      font-size: 12px;
      margin: 0;
      padding: 0;
    }

  }

}
