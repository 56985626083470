@import '../../../styles/_mixins.scss';

.container {
  background-color: $white;
  padding: 8px 12px;
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.30);
  border-radius: 3px;
  &.is-stacked {
    position: fixed;
  }
  p {
    margin: 0px;
    color: $black;
  }
  .stacked {
    margin-top: 10px;
    p {
      font-size: 12px;;
    }
  }
}