@import "../../styles/_variables.scss";
@import "../../styles/_mixins.scss";

.containerActions {
  position: absolute;
  width: 157px;
  right: 10px;
  top: -20px;
  border-radius: 8px;
  background-color: $bg-color;
  box-shadow: 0px 4px 32px rgba(0, 0, 0, 0.12);
  border: 1px solid rgba(113, 122, 151, 0.21);
  padding: $margin-sm;
  div {
    display: flex;
    cursor: pointer;
    width: 100%;
    &.disabled {
      pointer-events: none;
      opacity: 0.2;
    }

    &:not(:last-child) {
      margin-bottom: 10px;
    }

    p {
      margin: 0;
      width: 100%;
      color: $text-color;
      &:hover {
        text-decoration: underline;
      }
    }
  }
}

.dashboardsList {

  .row {
    cursor: pointer;
    flex-wrap: nowrap;
    @include remove-tap-highlight();

    &.rowHeader {
      border: none;
      margin-bottom: $margin-sm !important;
    }

    &:not(.rowHeader) {
      p {
        font-family: 'OpenSans-SemiBold';
        color: $black;
        font-size: $regular;
      }
    }

    &:not(:last-child) {
      margin-bottom: $margin-md;
    }

    p {
      margin: 0;
    }

    > div {
      flex: 1;
      z-index: 0;
      .cellName {
        display: flex;
        align-items: center;
        p {
          color: $primary-color;
        }
        svg {
          color: rgba($black, 0.3);
          margin-left: 5px;
        }
      }

      .cellManager {
        p {
          width: 100%;
        }
      }



      &:nth-last-child(2) {
        max-width: 90px;
        .cellState {
          &.disabled {
            pointer-events: none;
            opacity: 0.3;
          }
          .switch {
            position: relative;
            
            >div:nth-child(1) {
              &:after{
                content: '';
                position: absolute;
                z-index: 2;
                top: 2px;
                left: 2px;
                display: block;
                background-color: white;
                border-radius: 11px;
                width: calc(100% - 4px);
                height: calc(100% - 4px);
                pointer-events: none;
              }
              >div {
                position: relative;
                top: 2px !important;
                z-index: 3;
                &:first-child {
                  left: 6px;
                }
                &:last-child {
                  right: 2px !important;
                }
              }
            }

            >div:nth-child(2) {
              position: relative;
              z-index: 3;
              pointer-events: none;
              width: 12px !important;
              height: 12px !important;
              margin: 5px;
            }
          }
        }
      }

      &:last-child {
        flex: 0.05;
        min-width: 17px;
        .cellAction {
          &.disabled {
            .toggle {
              display: none;
            }
          }
          .positionModal {
            position: relative;
            top: -10px;
            right: -25px;
          }
        }
      }

    }
  }
}
