@import '../../../../styles/_mixins.scss';

.container {
  .border {
    padding-bottom: 20px;
    margin-bottom: 20px;
    border-bottom: 1px solid $grey;
  }
  .legend {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
    .color {
      min-width: 100px;
      label {
        display: block;
        @include label;
        margin-bottom: 5px;
      }
    }
  }
  .positions {
    margin-top: 20px;
    label {
      display: block;
      @include label;
      margin-bottom: 6px;
    }
    .options {
      button {
        position: relative;
        min-width: 0px;
        background-color: transparent;
        border: 1px solid $grey;
        margin-right: 10px;
        color: $black;
        padding: 15px 28px;
        &.active {
          background-color: $primary-color;
          color: $white;
          &:after {
            background-color: $white;
          }
        }
        &:after {
          content: '';
          position: absolute;
          width: 18px;
          height: 5px;
          background-color: $grey;
          border-radius: 5px;
        }
        &.TL {
          &:after {
            top: 5px;
            left: 5px;
          }
        }
        &.TC {
          &:after {
            top: 5px;
            left: 28px;
          }
        }
        &.TR {
          &:after {
            top: 5px;
            right: 5px;
          }
        }
        &.LC {
          &:after {
            top: 22px;
            left: 5px;
          }
        }
        &.RC {
          &:after {
            top: 22px;
            right: 5px;
          }
        }
        &.BL {
          &:after {
            bottom: 5px;
            left: 5px;
          }
        }
        &.BC {
          &:after {
            bottom: 5px;
            left: 28px;
          }
        }
        &.BR {
          &:after {
            bottom: 5px;
            right: 5px;
          }
        }
      }
    }
  } 
  .list {
    margin-top: 20px;
    .row {
      display: flex;
      align-items: center;
      margin-bottom: 10px;
      &:not(.label) {
        border: 1px solid $grey;
        border-radius: $radius;
        p {
          @include bold;
        }
      }
      .col {
        padding: 5px 10px;
        width: calc(100% / 3);
        display: flex;
        position: relative;
        p {
          max-width: 100%;
          @include label;
          margin: 0px;
          text-overflow: ellipsis;
          overflow: hidden;
        }
        .color {
          position: relative;
          width: 30px;
          height: 30px;
          border-radius: 2px;
          box-shadow: inset 0 0 0 1px rgba(110, 113, 119, 0.3);
        }
        .colorPicker {
          position: absolute;
          top: 0;
          z-index: 100;
        }
        &:not(:first-child) {
          justify-content: flex-end;
        }
      }
    }
  }
}