@import '../../../styles/_mixins.scss';

.embed {
  display: flex;
  padding: 0 !important;

  nav {
    position: relative;
    width: 20%;

    &:after {
      content: '';
      position: absolute;
      display: block;
      right: 0;
      top: $margin-md;
      width: 1px;
      height: calc(100% - 2 * $margin-md);
      background-color: $grey;
    }

    a {
      position: relative;
      display: block;
      padding: $margin-sm;
      font-family: 'OpenSans-Bold';
      text-decoration: none;
      transition: all .3s ease-in-out;

      &:after {
        content: '';
        position: absolute;
        bottom: 0;
        left: 0;
        display: block;
        width: 0;
        height: 100%;
        background-color: $primary-color;
        transition: all .3s ease-in-out;
      }

      &.active {
        color: $primary-color;
        &:after { width: 3px; }
      }
    }
  }
  .tabs {
    width: 80%;
    padding: $margin-md;
  }
}