@import '../../../../styles/_mixins.scss';

.share {
  .field {
    @include field;
    margin-bottom: 20px;
    .input {
      display: flex;
      align-items: center;
      max-width: 100%;
    }
    textarea {
      &.input {
        min-height: 140px;
      }
    }
    .copy {
      display: flex;
      align-items: center;
      button {
        background-color: transparent;
        border: none;
        color: $primary-color;
        min-width: 0px;
        padding: 10px 0px;
        display: flex;
        justify-content: center;
        svg {
          margin-left: 8px;
          font-size: 16px;
        }
      }
      .success {
        margin: 0px 20px;
        @include bold;
        color: $success;
      }
    }
  }
}