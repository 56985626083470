@import "../../../styles/_variables.scss";
@import "../_form-mixin.scss";

.input {
  display: flex;
  align-items: center;
  label {
    @include label;
  }

  .inputColor {
    border: none;
    outline: none;
    box-shadow: none;
    display: inline;
    opacity: 0;
  }

  .inputText {
    @include input();
    max-width: 78px;
  }

  &:not(.inline) {
    label + .color {
      margin-left: $margin-xs;
    }
  }

  .color {
    width: 50px;
    height: 50px;
    border-radius: 2px;
    box-shadow: inset 0 0 0 1px rgba(110, 113, 119, 0.3);
    // margin-left: $margin-xs;
  }

}