@import '../../styles/_mixins.scss';

.editDashboard {
  .container {
    @include container();
    .header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: $margin-sm;

      .backward {
        @include backward();
      }

      h1 {
        margin: 0 auto 0 $margin-sm;
        padding-left: $margin-sm;
        border-left: solid 1px $grey;
      }

      .containerButton {
        display: flex;
        button {
          margin-left: $margin-sm;
          &.saved {
            background-color: $success;
            border: 2px solid $success;
          }
        }
      }
    }
  }
  .content {
    @include content();
    padding: 0;

    >nav {
      a {
        position: relative;
        display: inline-block;
        width: calc(100% / 3);
        padding: $margin-sm;
        border: solid 1px $grey;
        font-family: 'OpenSans-Bold';
        text-align: center;
        text-decoration: none;
        transition: all .3s ease-in-out;

        &.disabled {
          pointer-events: none;
          opacity: 0.3;
        }

        &:after {
          content: '';
          position: absolute;
          bottom: 0;
          left: 0;
          display: block;
          width: 100%;
          height: 0px;
          background-color: $primary-color;
          transition: all .3s ease-in-out;
        }

        &.active {
          color: $primary-color;
          border-color: $primary-color;
          background-color: $primary-light-color;
          &:after { height: 3px; }
        }
      }
    }

    .tabs>div {
      padding: $margin-md;
    }

  }
}