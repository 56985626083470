@import "../../../styles/_variables.scss";
@import "../_form-mixin.scss";

.container-input-email {
  position: relative;

  label {
    @include label;
  }

  &.disabled {
    input {
      color: $text-light !important;
    }
  }

  &.inline {
    @include inline;
  }

  &:not(.inline) {
    label + .input {
      margin-top: 10px;
    }
  }

  .input {
    @include input;
  }
  &.primary {
    @include primary;
  }
}
