@import '../../styles/_mixins.scss';

.createDashboard {
  .container {
    @include container();
    .header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: $margin-md;

      .backward {
        @include backward();
      }

      h1 {
        margin: 0 auto 0 $margin-sm;
        padding-left: $margin-sm;
        border-left: solid 1px $grey;
      }

      .containerButton {
        display: flex;
        button {
           margin-left: auto;
        }
      }
    }

  }
  .content {
    @include content();
  }
}