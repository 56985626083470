@import '../../../styles/_mixins.scss';

.data {
  .sources {
    border: 2px solid $primary-color;
    border-radius: 5px;
    margin-bottom: 15px;
    overflow: hidden;
    button {
      background-color: transparent;
      border: 2px solid transparent;
      color: $primary-color;
      border-radius: 0px;
      &.active {
        background-color: $primary-color;
        border: 2px solid $primary-color;
        color: $white;
      }
    }
  }
  .row {
    display: flex;
    justify-content: space-between;
    .containerField {
      width: calc(50% - 15px)
    }
    &.end {
      justify-content: flex-end;
    }
  }
  .update {
    margin-top: 15px;
  }
  .containerField {
    @include containerField();
    &.switch {
      display: flex;
      align-items: center;
      p {
        margin: 0px 0px 5px 8px;
        @include label;
      }
    }
  }
  .loader {
    width: 100%;
    min-height: 238px;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}