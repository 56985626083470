@import '../../../styles/_mixins.scss';

.container {
  position: relative;
  overflow: hidden;
  width: 100%;
  height: 100%;
  background-color: $white;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 20px;
  &:not(.filter) {
    background: #FFFFFF;
    border: 1px solid #CFD0D3;
    box-shadow: 0px 1px 12px rgba(0, 0, 0, 0.1);
    border-radius: 5px;
  }
  &.filter {
    .name, .actions {
      display: none;
    }
  }
  ::-webkit-scrollbar {
    width: 10px;
    background: transparent ; 
  }
  /* Track */
  ::-webkit-scrollbar-track {
    border-radius: 10px;
  }
  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #D8D8D8 ; 
    border-radius: 10px;
  }
  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: $primary-color; 
  }
  .name {
    font-family: "OpenSans-SemiBold";
    position: absolute;
    display: block;
  }
  .actions {
    z-index: 10;
    position: absolute;
    display: flex;
    justify-content: flex-end;
    align-items: flex-start;
    top: 10px;
    right: 15px;
    button {
      @include transition;
      background-color: transparent;
      border: none;
      min-width: 0px;
      padding: 0px;
      color: $primary-color;
      font-size: 20px;
      margin-left: 10px;
      &.filter {
        color: $black;
        font-size: 28px;
        transform: translateY(-5px);
        position: relative;
        &:hover {
          transform: translateY(-5px) scale(1.1);
        }
        &.active {
          opacity: 0.3;
        }
        .puce {
          position: absolute;
          top: 16px;
          left: -6px;
          background-color: $primary-color;
          width: 10px;
          height: 10px;
          border-radius: 10px;
        }
      }
      &.active {
        opacity: 0.3;
      }
      &:hover {
        transform: scale(1.1);
      }
    }
  }
  .legend {
    position: absolute;
    background-color: $white;
    overflow-y: auto;
    overflow-x: hidden;
    z-index: 10;
    height: calc(100% - 50px);
    top: 32px;
    right: 15px;
    @include transition;
    transform: translateX(260px);
    width: 190px;
    .description {
      padding: 0px 10px;
    }
    &.active {
      transform: translateX(0px);
    }
    ul {
      margin: 0px 0px;
      padding: 0px;
      list-style: none;
      li {
        padding: 5px 10px;
        display: flex;
        align-items: flex-start;
        .icon {
          background-color: red;
          width: 5px;
          min-width: 5px;
          height: 12px;
          margin-top: 0px;
          border-radius: 10px;
          margin-right: 5px;
        }
        p {
          font-size: 12px;
          line-height: 12px;
          margin: 0px;
          max-width: 200px;
        }
      }
    }
  }
  .filters {
    position: absolute;
    background-color: $white;
    overflow-y: auto;
    width: 260px;
    z-index: 10;
    height: calc(100% - 20px);
    top: 10px;
    right: 10px;
    @include transition;
    transform: translateX(280px);
    background: #FFFFFF;
    border: 1px solid #CFD0D3;
    box-shadow: 0px 1px 12px rgba(0, 0, 0, 0.1);
    border-radius: 5px;
    padding: 8px 10px;
    &.active {
      transform: translateX(0px);
    }
    .title {
      display: flex;
      justify-content: space-between;
      color: $black;
      p {
        font-family: "OpenSans-SemiBold";
        color: $black;
      }
      button {
        background-color: transparent;
        border: none;
        min-width: 0px;
        padding: 0px;
        color: $black;
        font-size: 20px;
        transform: translateY(-3px);
      }
    }
    .range {
      overflow: hidden;
      margin-top: 20px;
      display: flex;
      justify-content: center;
      width: 100%;
      padding: 20px 15px;
    }
  }
  .graph {
    width: 100%;
    height: calc(100% - 20px);
    transform: translateY(10px);
    svg {
      overflow: hidden;
    }
    &.is-legend {
      width: calc(100% - 190px) ;
    }
    // &.legend {
    //   width: 100%;
    //   height: 100%;
    // } 
  }
}