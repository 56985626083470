@import '../../../styles/_mixins.scss';

.container {
  position: relative;
  height: 100%;
  width: 100%;
  .circle {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    p {
      margin: 0;
      color: inherit;
    }
  }
  .tooltip {
    z-index: 300;
    position: absolute;
    width: auto;
    pointer-events: none;
    opacity: 0;
    &.active {
      opacity: 1;
    }
  }
}
