@import '../../../styles/_mixins.scss';

.container {
  display: flex;
  flex-wrap: wrap;
  .form {
    width: 70%;
    border-right: solid 1px $grey;
    padding-right: $margin-md;

    .containerField {
      @include containerField();
    }
    
    .row {
      display: grid;
      grid-gap: $margin-md;
      grid-template-columns: repeat(2, 1fr);
    }
  }

  .file {
    width: 30%;
    padding-left: $margin-md;
  }

}