@import '../../../styles/_mixins.scss';
@import '../../../styles/_variables.scss';

.containerActions {
  position: absolute;
  width: 157px;
  right: 0px;
  top: -10px;
  border-radius: 8px;
  background-color: $bg-color;
  box-shadow: 0px 4px 32px rgba(0, 0, 0, 0.12);
  border: 1px solid rgba(113, 122, 151, 0.21);
  padding: $margin-sm;
  div {
    display: flex;
    cursor: pointer;
    width: 100%;
    &.disabled {
      pointer-events: none;
      opacity: 0.2;
    }

    &:not(:last-child) {
      margin-bottom: 10px;
    }

    p {
      margin: 0;
      width: 100%;
      color: $text-color;
      &:hover {
        text-decoration: underline;
      }
    }
  }
}

.indicators {
  display: flex;
  >div {
    width: 50%;
    .btns {
      width: 100%;
      display: flex;
      justify-content: space-between;
    }
    &:first-child {
      padding-right: $margin-sm;

      .listIndicators {
        padding: 0;
        margin: $margin-sm 0 0 0;
        width: 100%;
        li {
          width: 100%;
          list-style-type: none;
          &:not(:last-child) {
            margin-bottom: $margin-xs;
          }
        }

        .indicator {
          display: flex;
          flex: 1;
          justify-content: space-between;
          align-items: center;
          padding: 6px 3px 6px $margin-xs ;
          border: solid 1px $grey;
          border-radius: $radius;
          pointer-events: all;
          cursor: pointer;
          &.disabled {
            pointer-events: none;
            background-color: $white;
            opacity: 0.5;
          }
          .actions {
            display: flex;
            align-items: center;
            .action {
              display: flex;
              justify-content: center;
              align-items: center;
              .toggle {
                display: flex;
                justify-content: center;
                align-items: center;
                span {
                  display: block;
                  display: flex;
                  justify-content: center;
                  align-items: center;
                }
              }  
              .positionModal {
                position: relative;
                top: -10px;
                right: 20px;
              }
            }
          }
          .pen {
            margin-right: 5px;
            transform: translateY(1px);
            color: $warn;
            font-size: 18px;
            min-width: 0px;
            padding: 5px;
            display: flex;
            border-radius: 20px;
            background-color: transparent;
            border: none;
            &:hover {
              background-color: rgba($warn, 0.2);
            }
          }
          &.active {
            .pen {
              color: $success;
              &:hover {
                background-color: rgba($success, 0.2);
              }
            }
          }
          p {
            margin: 0;
          }
        }

      }

    }
    &:last-child {
      padding-left: $margin-sm;
      .containerField {
        @include containerField();
      }
    }
  }
}