@import '../../styles/_mixins.scss';

.container {
  width: 400px;
  h2 {
    font-size: 18px;
    margin: 0px 0px 10px 0px;
  }
  .row {
    margin-bottom: 10px;
  }
  button {
    margin-top: 10px;
  }
}