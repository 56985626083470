@import '../../../styles/variables.scss';
@import '../form-mixin.scss';

.list-tags{

  label {
    @include label;
  }

  &.inline{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    label{
      margin-right: 10px;
    }
  }

  &:not(.inline){
    label+ul{
      margin-top: 10px;
    }
  }

  ul{
    padding: 0;
    margin: 0;
    li{
      @include tagform;
      margin-bottom: 4px;
    }
  }
}
