@import '../../../styles/_variables.scss';

.selectIcons {
  position: relative;

  &.sm {
    .selectedIcon .containerIcon {
      min-width: 40px;
      height: 40px;
    }
    .gridIcon {
      top: 50px;
    }
  }
  &.md {
    .selectedIcon .containerIcon {
      min-width: 50px;
      height: 50px;
    }
    .gridIcon {
      top: 60px;
    }
  }

  .selectedIcon {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    max-width: 63px;
    border-radius: $radius;
    cursor: pointer;

    &.primary {
      background-color: $white;
      border: solid 1px rgba(110, 113, 119, 0.3);
    }

    &.secondary {
      // border: solid 1px rgba(110, 113, 119, 0.3);
    }

    .caret {
      position: relative;
      top: 2px;
      left: -2px;
      padding: 0 $margin-xs 0 0;
      pointer-events: none;
    }
    
    .containerIcon {
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 20px;
    }
  }

  .gridIcon {
    position: absolute;
    top: 0;
    z-index: 1;
    width: 300px;
    max-height: 210px;
    background-color: $bg-color;
    box-shadow: 0px 18px 32px rgba(208, 210, 218, 0.15);
    border: solid 1px rgba(110, 113, 119, 0.2);
    border-radius: 10px;
    overflow: hidden;
    > div {
      display: grid;
      grid-template-columns: repeat(5, 1fr);
      grid-gap: 5px;
      padding: 5px 0;
      overflow-y: scroll;
      max-height: 200px;
      .icon {
        font-size: 20px;
        color: $text-color;
        cursor: pointer;
        text-align: center;
      }
    }
  }
}
