@import "../../styles/_variables.scss";
@import "../../styles/_mixins.scss";

@mixin inline {
  display: flex;
  justify-content: space-between;
  align-items: center;
  label {
    margin-right: 10px;
  }
}

@mixin text {
  font-family: "OpenSans-SemiBold", sans-serif;
  font-weight: normal;
  font-size: $regular;
  line-height: 17px;
  color: $black;
}

@mixin input {
  display: block;
  border: none;
  outline: none;
  border-radius: 0px;
  width: 100%;
  min-height: 50px;
  box-sizing: border-box;
  background-color: $white;
  border: solid 1px $grey;
  border-radius: $radius;
  padding: 7px $margin-xs;
  @include text;

  // @media screen and (min-width: $sm-screen) {
  //   min-width: 220px;
  // }

  &::placeholder {
    @include text;
    color: $text-light;
  }
}

@mixin tagform {
  @include tag;
}

@mixin primary {
  .input {
    @include input;
    min-height: 60px;
    background-color: $white;
    box-shadow: 0px 18px 32px rgba(208, 210, 218, 0.15);
    border-radius: 0;
    /* Change the white to any color */
    &:-webkit-autofill,
    &:-webkit-autofill:hover,
    &:-webkit-autofill:focus,
    &:-webkit-autofill:active {
      -webkit-box-shadow: 0 0 0 30px $white inset !important;
    }

    /*Change text in autofill textbox*/
    &:-webkit-autofill {
      -webkit-text-fill-color: $text-color !important;
    }
  }
}
