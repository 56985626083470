@import '../../styles/_variables.scss';

.container {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  border-radius: $radius;
  background-color: $white;
  border: solid 1px rgba(110, 113, 119, 0.3);
  max-width: 100px;
}
