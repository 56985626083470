@import '../../../styles/_mixins.scss';

.container {
  display: flex;
  .layout {
    width: calc(66.66% + 10px);
    label {
      @include label;
    }
    .list {
      margin: 20px 20px 0px 0px;
      .row {
        display: flex;
        align-items: center;
        margin-bottom: 10px;
        &.disabled {
          opacity: 0.3;
        }
        p {
          @include label;
          margin: 0px;
        }
        &:not(.label) {
          border: 1px solid $grey;
          padding: 5px 0px;
          border-radius: $radius;
          p {
            @include bold;
          }
        }
        .col {
          padding: 5px 10px;
          width: calc(90% - 100px);
          display: flex;
          p {
            width: 100%;
            overflow: hidden;
            text-overflow: ellipsis;
          }
          &:first-child {
            width: 10%;
          }
          &:last-child {
            width: 130px;
            display: flex;
            justify-content: flex-end;
          }
          .select {
            display: flex;
            justify-content: flex-end;
            align-items: center;
            position: relative;
            cursor: pointer;
            p {
              margin-right: 10px;
              min-width: 50px;
            }
            .toggle {
              display: flex;
              justify-content: center;
              align-items: center;
              font-size: 20px;
              cursor: pointer;
            }
            ul {
              width: 90px;
              margin: 0px;
              position: absolute;
              top: -4px;
              right: 6px;
              background-color: $white;
              padding-left: 0px;
              list-style: none;
              border: 1px solid $grey;
              border-radius: $radius;
              z-index: 100;
              li {
                padding: 5px;
                &:hover {
                  background-color: rgba($grey, 0.3);
                }
              }
            } 
          }
        }
      }
    }
    &:not(:first-child) {
      width: 33%;
      border-left: 1px solid $grey;
      padding-left: 20px ;
      .preview {
        margin-top: 10px;
        background-color: rgba($grey, 0.3);
        padding: 10px;
        border-radius: 5px;
        display: flex;
        flex-wrap: wrap;
        .col {
          @include transition;
          &-1 {
            width: 100%;
          }
          &-2 {
            width: calc(50%);
          }
          &-3 {
            width: calc(75%);
          }
          &-4 {
            width: calc(25%);
          }
        }
        .block {
          background-color: $primary-color;
          display: flex;
          height: 70px;
          justify-content: center;
          align-items: center;
          border-radius: 5px;
          margin: 2px;
          padding: 10px 0px;
          font-size: 12px;
          color: $white;
          text-align: center;
        }
      }
    }
  }
}