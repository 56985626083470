@import "../../styles/_variables.scss";

.inputFile {
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 100%;
  width: 100%;
  border-radius: 8px;

  input + div:not(.dropZone) {
    background-color: rgba(110, 113, 119, 0.2);
    box-shadow: 0 0 0 6px rgba(110, 113, 119, 0.2);
    border: none;
    border-radius: $radius;
    text-align: center;
  }

  > .dropZone {
    position: relative;
    z-index: 2;
    width: 100%;
    height: 100%;
    label {
      font-family: "OpenSans-SemiBold", sans-serif;
      font-size: $regular;
      line-height: 17px;
      color: $text-color;
    }

    label + .containerButton {
      margin-top: 10px;
    }

    .containerButton {
      justify-content: flex-end;
      button {
        display: flex;
        flex-direction: column;
        border: 2px dashed $primary-color;
        background-color: $primary-light-color;
        cursor: pointer;
        width: 100%;
        color: $primary-color;
        font-size: $regular;
        line-height: 17px;
        white-space: inherit;
        padding: 0;
        overflow: hidden;
        min-height: 250px;

        // &:not(.withFile) {
        //   padding: $margin-lg;
        // }

        img { // PREVIEW 
          width: 100%;
          height: 100%; 
          object-fit: cover;
        }

        svg {
          margin-bottom: 5px;
        }

        span {
          vertical-align: inherit;
          pointer-events: none;
        }

        .underline {
          text-decoration: underline;
        }

        &.withFile > span,
        &.withFile > svg {
          visibility: hidden;
        }
        .filesName {
          display: inline-block;
          overflow: hidden;
          width: 100%;
          max-width: calc(100% - 20px);
          flex: 1;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }
      .removeFile {
        display: flex;
        justify-content: space-between;
        margin-top: $margin-xs;
        p {
          font-family: "OpenSans-SemiBold", sans-serif;
          font-size: $regular;
          line-height: 17px;
          color: $primary-color;
          text-decoration: underline;
          cursor: pointer;
        }

        .icon {
          cursor: pointer;
          &:hover svg { color: $primary-color; }
          svg {
            pointer-events: none;
            color: $text-light;
          }
        }

      }
    }
  }
}
