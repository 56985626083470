@import './_variables.scss';

@mixin container {
  margin: $margin-md;
  width: calc(100% - 2 * $margin-md);
  @media screen and (min-width: 1205px) {
    max-width: 1165px;
    margin: $margin-md auto;
  }
}


@mixin content{
  background-color: $white;
  padding: $margin-md;
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.16);
  min-height: 300px;
}

@mixin flex-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

@mixin bold {
  font-family: "OpenSans-Bold";
}

@mixin label {
  font-family: "OpenSans-SemiBold", sans-serif;
  font-size: $regular;
  line-height: 17px;
  color: $text-color;
  white-space: nowrap;
}

@mixin transition {
  transition: all .3s ease-in-out;
}

@mixin containerField{
  margin-bottom: $margin-md;
}

@mixin backward {
  font-family: 'OpenSans-SemiBold';
  margin: 0;
  color: $primary-color;
  cursor: pointer;
  height: 14px;
  svg {
    position: relative;
    top: 2px;
  }
}

@mixin field {
  label {
    font-family: "OpenSans-SemiBold", sans-serif;
    font-size: 13px;
    line-height: 17px;
    color: #666666;
    white-space: nowrap;
  }
  .input {
    display: block;
    border: none;
    outline: none;
    margin-top: 8px;
    border-radius: 0px;
    width: 100%;
    min-height: 50px;
    box-sizing: border-box;
    background-color: $white;
    border: solid 1px $grey;
    border-radius: $radius;
    padding: 7px $margin-xs;
    font-family: "OpenSans-SemiBold", sans-serif;
    font-weight: normal;
    font-size: $regular;
    line-height: 17px;
    color: $black;
  }
}

@mixin tag($style: '') {
  font-family: 'OpenSans-SemiBold';
  display: inline-block;
  color : $black;
  font-size: $regular;
  background-color: $white;
  border: solid 1px $grey;
  padding: $margin-xs $margin-sm;
  box-sizing: border-box;
  border-radius: 5px;
  cursor: pointer;

  &:not(:last-child){
    margin-right: 5px;
  }

  @if $style == primary {
    background-color: $primary-color;
  }@if $style == light-primary {
    background-color: $primary-color;
    background-color: rgba(106, 161, 135, 0.3);
  } @else if $style == warn {
    background-color: $warn;
  }

  &.selected{
    background-color: $primary-color;
    border: solid 1px $primary-color;
    color: $white;
  }

  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-tap-highlight-color: rgba(0,0,0,0);
  -webkit-tap-highlight-color: transparent;
}
@mixin remove-highlight(){
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

@mixin remove-tap-highlight(){
  -webkit-tap-highlight-color: rgba(0,0,0,0);
  -webkit-tap-highlight-color: transparent;
}